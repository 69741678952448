@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");

* {
  box-sizing: border-box;
  margin: 0;
}


body {
  min-height: 2000px;
  box-sizing: border-box;
  font-family:  -apple-system, BlinkMacSystemFont, 'Roboto', Helvetica Neue,sans-serif;
  background: #f6f6f6;
}


.wrapper {
  padding: 0 10px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .wrapper {
    width: 550px;
    padding: 0 30px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .wrapper {
    width: 940px;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    width: 1190px;
  }
}