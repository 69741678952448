
.SearchSection {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .SearchSection {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 auto;
  }
  .SearchForm {
    margin: 0 auto 10px;
  }
  .showing {
    margin: 0 auto;
  }
}

.SearchForm {
  border-bottom: 1px solid #2d2d2d;
  min-width: 280px;
}

.SearchForm__input {
  font-size: 1.4rem;
  border: none;
  background: inherit;
  outline: none;
  padding: 5px 10px;
  min-width: 140px;
  max-width: 300px;
  color: tomato;
}

.xbutton {
  width: 20px;
}
.SearchForm__input::placeholder {
  font-size: 1.4rem;
  color:#57abffad;
}

.showing {
  padding: 0 10px;
}
