.About {
  margin-bottom: 30px;
}

.titles {
  margin-bottom: 20px;
  display: flex;
}

.titles * {
  margin: 0 30px 0 0;
}


@media (max-width: 576px) {
  .titles * {
    margin: 0 8px 0 0;
  }
  .titles {
    font-size: 0.5rem;
  }
} 
@media (min-width: 577px) and (max-width: 768px) {
  .titles {
    font-size: 0.6rem;
  }
  .titles * {
    margin: 0 20px 0 0;
  }
}

h2 {
  margin-bottom: 10px;
}