.Tags__list {
  height: 30px;
}

.Tag {
  opacity: 0.85;
  margin-bottom: 5px;
  filter: saturate(90%);
  
}

.Tag:hover {
  cursor: pointer;
  opacity: 1;
  filter: none;
  box-shadow: 1px 1px 1px #595959;
}